import pick from 'lodash/pick';
import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Button, Icon, Image } from 'semantic-ui-react';
import { closePopup, usePopup } from '../../lib/popup';
import involutionLogo from '../../assets/images/Frame.png';

import Paths from '../../constants/Paths';
import DroppableTypes from '../../constants/DroppableTypes';
import AddStep from './AddStep';
import EditStep from './EditStep';

import styles from './Boards.module.scss';

function ItemsNode({ item, index, currentId, handleUpdate, handleDelete, canEdit }) {
  const EditPopup = usePopup(EditStep);
  console.log(item);
  return (
    <Draggable
      key={item.id}
      draggableId={item.id}
      index={index}
      isDragDisabled={!item.isPersisted || !canEdit}
    >
      {({ innerRef, draggableProps, dragHandleProps }) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <div {...draggableProps} ref={innerRef} className={styles.tabWrapper}>
          <div className={classNames(styles.tab, item.id === currentId && styles.tabActive)}>
            {item.isPersisted ? (
              <>
                <Link
                  {...dragHandleProps} // eslint-disable-line react/jsx-props-no-spreading
                  to={Paths.BOARDS.replace(':id', item.id)}
                  title={item.name}
                  className={styles.link}
                >
                  {item.name}
                </Link>
                {canEdit && (
                  <EditPopup
                    defaultData={pick(item, 'name')}
                    onUpdate={(data) => handleUpdate(item.id, data)}
                    onDelete={() => handleDelete(item.id)}
                  >
                    <Button className={classNames(styles.editButton, styles.target)}>
                      <Icon fitted name="pencil" size="small" />
                    </Button>
                  </EditPopup>
                )}
              </>
            ) : (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <span {...dragHandleProps} className={styles.link}>
                {item.name}
              </span>
            )}
          </div>
        </div>
      )}
    </Draggable>
  );
}

const Boards = React.memo(
  ({ items, currentId, canEdit, onCreate, onUpdate, onMove, onDelete, projects, projectId }) => {
    const tabsWrapper = useRef(null);
    console.log(projects);

    const handleWheel = useCallback(({ deltaY }) => {
      tabsWrapper.current.scrollBy({
        left: deltaY,
      });
    }, []);

    const handleDragStart = useCallback(() => {
      closePopup();
    }, []);

    const handleDragEnd = useCallback(
      ({ draggableId, source, destination }) => {
        if (!destination || source.index === destination.index) {
          return;
        }

        onMove(draggableId, destination.index);
      },
      [onMove],
    );

    const handleUpdate = useCallback(
      (id, data) => {
        onUpdate(id, data);
      },
      [onUpdate],
    );

    const handleDelete = useCallback(
      (id) => {
        onDelete(id);
      },
      [onDelete],
    );

    const AddPopup = usePopup(AddStep);

    return (
      <div className={styles.wrapper} onWheel={handleWheel}>
        <div className={styles.projectList}>
          {projects.map((project) => (
            <div key={project.id}>
              <div className={styles.projectAdd}>
                <Link
                  key={project.id}
                  to={
                    project.firstBoardId
                      ? Paths.BOARDS.replace(':id', project.firstBoardId)
                      : Paths.PROJECTS.replace(':id', project.id)
                  }
                  className={classNames(
                    styles.project,
                    project.id === projectId && styles.projectActive && styles.projectActiveLink,
                  )}
                >
                  {project.name}
                </Link>
                {canEdit && (
                  <AddPopup onCreate={onCreate}>
                    <Button icon="plus" className={styles.addButton} />
                  </AddPopup>
                )}
              </div>
              <div ref={tabsWrapper} className={styles.tabsWrapper}>
                <DragDropContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
                  <Droppable droppableId="boards" type={DroppableTypes.BOARD} direction="vertical">
                    {({ innerRef, droppableProps, placeholder }) => (
                      <div className={styles.wrapperTabs}>
                        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                        <div {...droppableProps} ref={innerRef} className={styles.tabs}>
                          {project.boards.map((item, index) => (
                            <ItemsNode
                              key={item.id}
                              item={item}
                              index={index}
                              currentId={currentId}
                              handleUpdate={handleUpdate}
                              handleDelete={handleDelete}
                              canEdit={canEdit}
                            />
                          ))}
                          {placeholder}
                        </div>
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </div>
          ))}
        </div>
        <div className={styles.logo}>
          <Image centered src={involutionLogo} size="small" />
        </div>
      </div>
    );
  },
);

Boards.propTypes = {
  items: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  currentId: PropTypes.string,
  canEdit: PropTypes.bool.isRequired,
  onCreate: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onMove: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  projects: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  projectId: PropTypes.string.isRequired,
};
ItemsNode.propTypes = {
  item: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  index: PropTypes.number.isRequired,
  currentId: PropTypes.string,
  handleUpdate: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  canEdit: PropTypes.bool.isRequired,
};

Boards.defaultProps = {
  currentId: undefined,
};

ItemsNode.defaultProps = {
  currentId: undefined,
};

export default Boards;
